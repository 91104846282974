import { Link, LinkProps } from "@tanstack/react-location"
import clsx from "clsx"
import {
  ButtonHTMLAttributes,
  forwardRef,
  AnchorHTMLAttributes,
  ForwardRefRenderFunction
} from "react"
import LoadingIcon from "../icon/LoadingIcon"

export type ButtonSize = "small" | "medium" | "large" | "xlarge"
interface BaseProps {
  color: "danger" | "danger-outline" | "portal" | "primary" | "secondary" | "tertiary"
  size?: ButtonSize
  isActive?: boolean
  isLoading?: boolean
  "data-testid"?: string
  shape?: "icon" | "text"
}
export type ButtonAsButton = BaseProps &
  Omit<ButtonHTMLAttributes<HTMLButtonElement>, "type"> & { type: "button" | "submit" }
export type ButtonAsAnchor = BaseProps &
  Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "type"> & {
    type: "anchor"
    disabled?: boolean
  }
export type ButtonAsLink = BaseProps &
  Omit<LinkProps, "type"> & { type: "link"; disabled?: boolean }
export type ButtonProps = ButtonAsButton | ButtonAsAnchor | ButtonAsLink

const Button: ForwardRefRenderFunction<null, ButtonProps> = (props, ref) => {
  const colorToCls = {
    danger: clsx("border-transparent bg-red-600 text-white shadow-sm"),
    "danger-outline":
      props.shape === "icon"
        ? clsx("border-red-600 text-red-600 shadow-sm")
        : clsx("border-red-600 text-red-600 shadow-sm"),
    portal: clsx("border-transparent text-grey-200"),
    primary:
      props.shape === "icon"
        ? clsx("border-transparent text-grey-800")
        : clsx("border-transparent bg-yellow-300 text-grey-900 shadow-sm"),
    secondary:
      props.shape === "icon"
        ? clsx("border-transparent text-grey-600")
        : clsx("border-transparent bg-grey-200 text-grey-800 shadow-sm"),
    tertiary: clsx("border-transparent text-grey-800")
  }

  const colorToHoverCls = {
    danger: clsx("hover:bg-red-800"),
    "danger-outline":
      props.shape === "icon"
        ? clsx("hover:border-red-800 hover:text-red-800")
        : clsx("hover:border-red-800 hover:text-red-800"),
    portal: clsx("hover:bg-grey-800 dark:hover:bg-grey-800 dark:hover:text-white"),
    primary: props.shape === "icon" ? clsx("hover:bg-grey-300") : clsx("hover:bg-yellow-400"),
    secondary:
      props.shape === "icon"
        ? clsx("hover:bg-grey-300 hover:text-grey-800")
        : clsx("hover:bg-grey-300"),
    tertiary: clsx("hover:bg-grey-300 ")
  }

  const colorToActiveCls: Partial<Record<BaseProps["color"], string>> = {
    danger: clsx("border-transparent bg-red-900 text-white shadow-sm"),
    "danger-outline": clsx("border-red-900 text-red-900 shadow-sm"),
    primary:
      props.shape === "icon"
        ? clsx("border-transparent bg-grey-400 text-grey-800 shadow-sm")
        : clsx("border-transparent bg-yellow-500 text-grey-900 shadow-sm"),
    secondary: clsx("border-transparent bg-grey-400 text-grey-800 shadow-sm"),
    tertiary: clsx("border-transparent bg-grey-400 text-grey-800")
  }

  const sizeToCls = {
    large: clsx("text-normal h-10 px-sm radius-xs"),
    medium: clsx("h-8 px-xs text-sm radius-xs"),
    small: clsx("h-6 px-xs text-xs leading-none radius-xs"),
    xlarge: clsx("h-14 px-md text-xl leading-none radius-xs")
  }

  const iconSizeToCls = {
    large: clsx("h-10 w-10 text-sm leading-none radius-xs"),
    medium: clsx("h-lg w-lg text-xs leading-none radius-xs"),
    small: clsx("h-md w-md text-xs leading-none radius-xs"),
    xlarge: clsx("h-14 w-14 text-sm leading-none radius-xs")
  }

  const disabled = props.disabled || props.isLoading
  const borderSizeSmall = props.size === "small" || props.size === "medium"

  const cls = clsx(
    "inline-flex select-none items-center justify-center whitespace-nowrap border font-medium leading-none",
    props.size === "small" ? "gap-2xs" : "gap-xs",
    props.shape === "icon"
      ? iconSizeToCls[props.size || "large"]
      : sizeToCls[props.size || "large"],
    props.isActive ? colorToActiveCls[props.color] : colorToCls[props.color],
    disabled ? "cursor-not-allowed opacity-50" : colorToHoverCls[props.color],
    borderSizeSmall ? "border-1" : "border-2",
    props.className
  )

  if (props.type === "anchor") {
    const { className, children, color, isLoading, size, type, ...attrs } = props
    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
      <a
        ref={ref}
        className={cls}
        onClick={disabled ? e => e.preventDefault() : undefined}
        {...attrs}
      >
        {children}
      </a>
    )
  }
  if (props.type === "link") {
    const { className, children, color, isLoading, size, type, ...attrs } = props
    return (
      <Link _ref={ref} className={cls} {...attrs}>
        {children}
      </Link>
    )
  }

  const { className, children, color, isLoading, size, shape, ...attrs } = props
  return (
    <button ref={ref} className={cls} {...attrs} disabled={disabled}>
      {isLoading && (
        <LoadingIcon isLoading size={size === "xlarge" ? 24 : size === "large" ? 20 : 16} />
      )}
      {((!isLoading && shape === "icon") || shape !== "icon") && children}
    </button>
  )
}

export default forwardRef(Button)
