import clsx from "clsx"
import {
  forwardRef,
  ForwardRefRenderFunction,
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState
} from "react"
import { getCanvasFont, getTextWidth } from "src/helpers/typography"
import Icon from "../icon/Icon"

// Styles are defined in front/src/styles/forms.css

type CommonProps = Omit<InputHTMLAttributes<HTMLInputElement>, "color" | "prefix" | "type"> & {
  controlSize?: "normal" | "small"
}

export type Props =
  | (CommonProps & {
      type: "checkbox"
      variant?: "normal" | "toggle" | "chip"
    })
  | (CommonProps & {
      type: "radio"
    })
  | (CommonProps & {
      // Not supported: range, color, file
      type?:
        | "date"
        | "datetime-local"
        | "email"
        | "month"
        | "password"
        | "search"
        | "tel"
        | "text"
        | "time"
        | "url"
        | "week"
        | "number"
      prefix?: string | JSX.Element
      suffix?: string | JSX.Element
      variant?: "normal" | "path"
      color?: "normal" | "pink" | "purple" | "teal"
      width?: "large" | "small"
    })

const Input: ForwardRefRenderFunction<HTMLInputElement, Props> = (props, ref) => {
  const id = props.id || props.name
  const prefixRef = useRef<HTMLDivElement>(null)
  const [prefixWidth, setPrefixWidth] = useState(0)
  const _prefix = props.type !== "radio" && props.type !== "checkbox" && props.prefix

  useEffect(() => {
    if (prefixRef.current && _prefix && typeof _prefix !== "string") {
      setPrefixWidth(prefixRef.current.offsetWidth)
    }
  }, [_prefix])

  const suffixRef = useRef<HTMLDivElement>(null)
  const [suffixWidth, setSuffixWidth] = useState(0)
  const _suffix = props.type !== "radio" && props.type !== "checkbox" && props.suffix

  useEffect(() => {
    if (suffixRef.current && _suffix && typeof _suffix !== "string") {
      setSuffixWidth(suffixRef.current.offsetWidth)
    }
  }, [_suffix])

  if (props.type === "radio") {
    const { className, controlSize, style, ...attrs } = props
    return (
      <input
        ref={ref}
        className={clsx("input-radio", controlSize === "small" && "is-small", className)}
        id={id}
        {...attrs}
      />
    )
  }

  if (props.type === "checkbox") {
    const { className, controlSize, style, variant, ...attrs } = props
    const id = attrs.id || attrs.name

    if (props.variant === "toggle") {
      return (
        <input
          ref={ref}
          className={clsx("input-toggle", controlSize === "small" && "is-small", className)}
          id={id}
          {...attrs}
        />
      )
    }

    if (props.variant === "chip") {
      return <input ref={ref} className={clsx("input-chip", className)} id={id} {...attrs} />
    }

    return (
      <input
        ref={ref}
        className={clsx("input-checkbox", controlSize === "small" && "is-small", className)}
        id={id}
        {...attrs}
      />
    )
  }

  const { className, color, controlSize, prefix, suffix, style, variant, width, ...attrs } = props
  const font = getCanvasFont(controlSize === "small" ? "text-sm" : "text-base")

  return (
    <div
      className={clsx(
        "group relative w-full typo-base-regular",
        width === "small" && "max-w-[8rem]",
        width === "large" && "max-w-[20rem]",
        className
      )}
    >
      {prefix && (
        <div ref={prefixRef} className="input-prefix pointer-events-none" id={`${id}--prefix`}>
          {prefix}
        </div>
      )}
      <input
        ref={ref}
        className={clsx(
          "input-text",
          controlSize && controlSize !== "normal" && `is-${controlSize}`,
          color && color !== "normal" && `is-${color}`,
          variant && variant !== "normal" && `is-${variant}`
        )}
        id={id}
        style={{
          ...style,
          ...(prefix && {
            paddingLeft: `calc(0.75rem + ${
              typeof prefix === "string" ? getTextWidth(prefix, font) : prefixWidth
            }px)`
          }),
          ...(suffix && {
            paddingRight: `calc(0.75rem + ${
              typeof suffix === "string" ? getTextWidth(suffix, font) : suffixWidth
            }px)`
          })
        }}
        {...attrs}
      />
      {props.type === "datetime-local" && (
        <div className="pointer-events-none absolute right-[0.75rem] top-0 z-10 flex h-full items-center justify-center">
          <Icon className="bg-white text-grey-600" icon="calendar" size={24} type="regular" />
        </div>
      )}
      {suffix && (
        <div ref={suffixRef} className="input-suffix" id={`${id}--suffix`}>
          {suffix}
        </div>
      )}
    </div>
  )
}

export default forwardRef(Input)
